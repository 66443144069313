import {
  SystemCheckRequestBodyType,
  SystemCheckResult,
} from '@lib/types/system-check'
import Router from 'next/router'
import platform from 'platform'
import {
  CAMERA_AND_MIC_ERRORS,
  MIN_SCREEN_SIZE,
  SUPPORTED_BROWSERS,
  UNSUPPORTED_USER_AGENT,
} from '@lib/constants/system-check'
import {
  CheckBrowserType,
  CheckCameraAndMicType,
  CheckCookiesType,
  CheckDeviceType,
  CheckScreenSizeType,
  CheckUserAgentType,
} from '@lib/types/system-check'

export const checkSupportedUserAgent = (): CheckUserAgentType => {
  const userAgent = platform.ua
  const userAgentSupported = !UNSUPPORTED_USER_AGENT.includes(userAgent)
  return { passed: userAgentSupported, userAgent }
}

export const checkSupportedDevice = (): CheckDeviceType => {
  const userAgent = platform.ua
  if (
    /windows phone/i.test(userAgent) ||
    /android/i.test(userAgent) ||
    /iPad|iPhone|iPod/.test(userAgent)
  ) {
    return { passed: false, device: 'mobile' }
  }

  return { passed: true, device: 'desktop' }
}

export const checkSupportedBrowser = (): CheckBrowserType => {
  const { name, version, os } = platform
  // check if browser is Microsoft Edge 80+ and os is not Mac os
  if (
    name === 'Microsoft Edge' &&
    /Windows/.test(os.family) &&
    parseInt(os.version) >= 7 &&
    parseInt(version) >= 80
  ) {
    return { passed: true, name, version }
  }
  // eslint-disable-next-line no-prototype-builtins
  if (SUPPORTED_BROWSERS.hasOwnProperty(name)) {
    return {
      passed: parseInt(version) >= parseInt(SUPPORTED_BROWSERS[name]),
      name,
      version,
    }
  } else {
    return { passed: false, name, version }
  }
}

export const checkCookiesSupport = (): CheckCookiesType => ({
  passed: navigator.cookieEnabled,
})

const getMedia = async (constraints) => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints)
    stream.getTracks().forEach((track) => track.stop())
    return { permission: true }
  } catch (error) {
    const { name } = error
    const { NOT_ALLOWED_ERROR, NOT_READABLE_ERROR } = CAMERA_AND_MIC_ERRORS

    if (name === NOT_ALLOWED_ERROR) return { permission: false }
    else if (name === NOT_READABLE_ERROR) {
      /* a hardware error occurred at the operating system, browser,
         or web page level which prevented access to the device.
         also returned when zoom or any other app is accessing the camera.
      */
      return { permission: false, notReadableError: true }
    } else {
      console.error('getMedia', name)
    }
    return { permission: false }
  }
}

export const checkCameraAndMicAccess = async (): Promise<
  CheckCameraAndMicType
> => {
  try {
    const getCameraAndMicrophonePermission = await getMedia({
      video: true,
      audio: true,
    })
    if (!getCameraAndMicrophonePermission.permission) {
      const getCameraPermission = await getMedia({ video: true })
      const getMicrophonePermission = await getMedia({ audio: true })
      return {
        microphone: { passed: getMicrophonePermission.permission },
        camera: {
          passed: getCameraPermission.permission,
        },
        isCameraReadable: { passed: !getCameraPermission.notReadableError },
      }
    }
    return {
      microphone: { passed: true },
      camera: {
        passed: true,
      },
      isCameraReadable: {
        passed: !getCameraAndMicrophonePermission.notReadableError,
      },
    }
  } catch (error) {
    console.error('checkCameraAndMic', error)

    return {
      microphone: { passed: false },
      camera: { passed: false },
      isCameraReadable: { passed: false },
    }
  }
}

export const checkSupportedScreenSize = (): CheckScreenSizeType => {
  const { HEIGHT, WIDTH } = MIN_SCREEN_SIZE

  return {
    passed: window.screen.height >= HEIGHT || window.screen.width >= WIDTH,
    height: window.screen.height,
    width: window.screen.width,
  }
}

export const prepareSystemCheckReqBody = (
  currentSystemCheckResults: SystemCheckResult,
  userId: number,
  classroomId: number,
  didSystemCheckPass: boolean
): SystemCheckRequestBodyType => {
  const {
    browser,
    camera,
    cookies,
    device,
    microphone,
    screenSize,
    userAgent,
    isCameraReadable,
  } = currentSystemCheckResults

  const formBody: SystemCheckRequestBodyType = {
    userId,
    activityDetails: {
      userAgent: userAgent.userAgent,
      device: {
        name: device.device,
        status: device.passed ? 'Pass' : 'Fail',
      },
      browser: {
        name: browser.name,
        version: browser.version,
        status: browser.passed ? 'Pass' : 'Fail',
      },
      screen: {
        resolution: {
          size: `${screenSize.width}x${screenSize.height}`,
          status: screenSize.passed ? 'Pass' : 'Fail',
        },
      },
      cookies: {
        status: cookies.passed ? 'Pass' : 'Fail',
      },
      page: Router.asPath,
      os: platform.os.toString(),
    },
    status: didSystemCheckPass ? 'Pass' : 'Fail',
  }

  // Microphone and Camera are not checked while performing silent system check
  if (microphone)
    formBody.activityDetails.microphone = {
      status: microphone.passed ? 'Pass' : 'Fail',
    }

  if (camera)
    formBody.activityDetails.camera = {
      status: camera.passed && isCameraReadable.passed ? 'Pass' : 'Fail',
    }

  if (classroomId) formBody.classroomId = classroomId

  return formBody
}
