import { useState, useEffect } from 'react'

type Sizes = {
  sm: boolean
  md: boolean
  lg: boolean
  xl: boolean
  '2xl': boolean
}

type Dimensions = {
  height: undefined | number
  width: undefined | number
}

type ReturnType = {
  dimensions: Dimensions
  sizes: Sizes
}

// Hook is based on Tailwindcss breakpoint system
const useResolution = (): ReturnType => {
  const [dimensions, setDimensions] = useState<Dimensions>({
    height: undefined,
    width: undefined,
  })
  const [sizes, setSizes] = useState<Sizes>({
    sm: false,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  })

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })

      const SCREEN_WIDTH = {
        SM: 640,
        MD: 768,
        LG: 1024,
        XL: 1280,
      }
      const { SM, MD, LG, XL } = SCREEN_WIDTH

      setSizes({
        sm: window.innerWidth <= SM,
        md: window.innerWidth >= SM && window.innerWidth <= MD,
        lg: window.innerWidth >= MD && window.innerWidth <= LG,
        xl: window.innerWidth >= LG && window.innerWidth <= XL,
        '2xl': window.innerWidth >= XL,
      })
    }
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { dimensions, sizes }
}

export default useResolution
