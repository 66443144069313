import { RecaptchaType } from '@lib/types/recaptcha'
import { useState } from 'react'

const useLazyRecaptcha = () => {
  const [recaptchaAPI, setRecaptchaAPI] = useState<RecaptchaType>(null)
  const [shouldRecaptchaLoad, setShouldRecaptchaLoad] = useState(false)

  return {
    recaptchaAPI,
    setRecaptchaAPI,
    shouldRecaptchaLoad,
    setShouldRecaptchaLoad,
  }
}

export default useLazyRecaptcha
