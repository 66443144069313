import { SIGNUP_SOURCE_LOCAL_STORAGE_KEY } from '@common/constants'
import { CClient } from '@common/utils'
import {
  generateUTMParametersDictFromCookie,
  getUTMParametersDictFromLocalStorage,
} from '@lib/analytics/track-utm'
import { MASKED_EMAIL } from '@lib/constants/common'
import { SIGNUP_REFERRER_LOCAL_STORAGE_KEY } from '@lib/constants/tracking'
import { ROLES } from '@lib/enums/dashboard'
import { SYSTEM_TIMEZONE } from '@lib/utils/date-native'
import {
  getFromLocalStorage,
  setToLocalStorage,
} from '@lib/utils/local-storage'
import { captureMessage } from '@sentry/node'
import Router from 'next/router'
import platform from 'platform'

const MAX_UA_LENGTH = 1024

export const registerUser = async (formBody) => {
  const url = '/api/v1/users/'
  const result = CClient(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formBody),
  })
  return result
}

export const requestLoginOtp = async (
  formBody,
  viaCall = false,
  viaWhatsapp = false,
  viaEmail = false,
  viaSms = false
) => {
  let url = '/api/v1/auth/phone/'
  if (viaCall) url += '?via=call'
  if (viaWhatsapp) url += '?via=whatsapp'
  if (viaEmail) url += '?via=email'
  if (viaSms) url += '?via=sms'

  const result = await CClient(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formBody),
  })

  if (result.status === 200) {
    const resultData = await result.json()
    if (resultData.email) {
      setToLocalStorage(MASKED_EMAIL, String(resultData.email), () => {
        console.error('error saving maskedEmail to localstorage')
      })
    } else {
      setToLocalStorage(MASKED_EMAIL, 'none', () => {
        console.error('error saving maskedEmail to localstorage')
      })
    }
  }

  return result
}

export const verifyOtpAndLogin = async (formBody) => {
  const url = '/api/v1/auth/token/'
  const result = await CClient(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(formBody),
  })

  return result
}

export const validateForm = () => {
  return {}
}

// TODO: Update type of keys
export const prepareRegistrationFormBody = (data: any) => {
  const {
    phone,
    name,
    email,
    role,
    childGrade,
    childGender,
    parentName,
    teacherEducationalQualification,
    teacherExperience,
    teacherGender,
    whatsappEnabled,
    hasLaptop,
    voucher,
  } = data
  const formBody = {
    name: name,
    email: email,
    phone: `+${phone}`,
    getOtp: true,
    preferences: {
      whatsappEnabled: whatsappEnabled,
    },
  }

  const referralCampaign = getFromLocalStorage('referralCampaign')
  const referrerUsername = getFromLocalStorage('referrerUsername')
  // send lead source information
  let leadSourceDict = getUTMParametersDictFromLocalStorage()
  if (!leadSourceDict) leadSourceDict = generateUTMParametersDictFromCookie()
  formBody['leadSource'] = leadSourceDict
  formBody['signupReferer'] =
    getFromLocalStorage(SIGNUP_REFERRER_LOCAL_STORAGE_KEY) || ''
  formBody['signupSource'] = data.signupSource
    ? data.signupSource
    : getFromLocalStorage(SIGNUP_SOURCE_LOCAL_STORAGE_KEY) ||
      document.location.pathname

  if (role === ROLES.TEACHER) {
    const teacher = {
      experience: teacherExperience.value,
      educationalQualification: teacherEducationalQualification.value,
    }
    formBody['teacher'] = teacher
    formBody['gender'] = teacherGender.value
  }
  if (role === ROLES.STUDENT) {
    formBody['student'] = {
      grade: childGrade.value,
      parentName: parentName,
    }
    formBody['gender'] = childGender.value
    formBody['hasLaptop'] = hasLaptop
    formBody['voucher'] = voucher
  }

  formBody['timezone'] = SYSTEM_TIMEZONE || ''
  let userAgent = platform.ua
  if (userAgent.length > MAX_UA_LENGTH) {
    captureMessage(`user agent not captured for ${email}: ${userAgent}`)
    userAgent = ''
  }
  formBody['signupUserAgent'] = userAgent
  formBody['referrerUsername'] = referrerUsername || null
  formBody['referralCampaignSlug'] = referralCampaign || null
  return formBody
}

export const handleLogout = () => {
  Router.push('/logout/?next=' + Router.asPath)
}
