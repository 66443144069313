import React, { Dispatch, SetStateAction } from 'react'
import dynamic from 'next/dynamic'
import { RecaptchaType } from '@lib/types/recaptcha'

const RecaptchaWithRefForward: React.ComponentType<any> = dynamic(
  import('@components/common/lazy-recaptcha/recaptcha-with-ref-forward')
)

const ReCAPTCHA: React.ComponentType<any> = React.forwardRef((props, ref) => (
  <RecaptchaWithRefForward {...props} forwardedRef={ref} />
))

type Props = {
  shouldRecaptchaLoad: boolean
  onChange: (recaptchaKey: string) => Promise<void> | void
  setRecaptchaAPI: Dispatch<SetStateAction<RecaptchaType>>
}

declare global {
  interface Window {
    isReflectTest: boolean
  }
}

const LazyRecaptcha = ({
  shouldRecaptchaLoad,
  onChange,
  setRecaptchaAPI,
}: Props) => {
  if (!shouldRecaptchaLoad || window.isReflectTest) return null

  return (
    <ReCAPTCHA
      size="invisible"
      sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      onChange={onChange}
      ref={(ref) => setRecaptchaAPI(ref)}
    />
  )
}

export default React.memo(LazyRecaptcha)
