export const PROJECT_STATES = {
  PENDING: 'Pending',
  SUBMITTED: 'Submitted',
  REVIEWED: 'Reviewed',
}

export const CLASS_STATES = {
  UPCOMING: {
    label: 'Upcoming',
    value: 'Upcoming',
  },
  COMPLETED: {
    label: 'Completed',
    value: 'Previous',
  },
  CANCELLED: {
    label: 'Cancelled',
    value: 'Cancelled',
  },
}

export const QUIZ_STATES = {
  PENDING: 'Pending',
  SUBMITTED: 'Submitted',
}

export const ROLES = {
  TEACHER: 'teacher',
  STUDENT: 'student',
}

export const CANCEL_REASONS = {
  CANCEL_REQUEST_BY_STUDENT: 'Student requested to cancel',
  CANCEL_REQUEST_BY_TEACHER: 'Teacher requested to cancel',
  STUDENT_ABSENT: "Student didn't show up for the class",
  TEACHER_ABSENT: "Teacher didn't show up for the class",
  STUDENT_UNINTERESTED: "Student didn't show any interest",
  STUDENT_DISCONNECTED: 'Student got disconnected',
  TEACHER_DISCONNECTED: 'Teacher got disconnected',
  CANCEL_REASON_OTHER: 'Other reasons',
}

export const CLASS_CARD_STATES = {
  UPCOMING: 'upcoming',
  ONGOING: 'ongoing',
  COMPLETED: 'completed',
}

export const UPLOAD_STATE = {
  INITIAL: 0,
  UPLOADING: 1,
  RETRY: 2,
  FAILED: 3,
  SUCCESSFUL: 4,
}
