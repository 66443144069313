import { defineMessage } from '@lingui/macro'

export const SYSTEM_CHECK_ERRORS = {
  microphone: {
    message: defineMessage({
      id: 'trialClass.systemCheck.checkFailMessage.microphone',
      message: 'Allow microphone access.',
    }),
    icon: 'MicOff',
  },
  camera: {
    message: defineMessage({
      id: 'trialClass.systemCheck.checkFailMessage.camera',
      message: 'Allow camera access.',
    }),
    icon: 'CameraOff',
  },
  isCameraReadable: {
    message: defineMessage({
      id: 'trialClass.systemCheck.checkFailMessage.isCameraReadable',
      message:
        'Close any other app that may be using your camera and try again.',
    }),
    icon: 'XCircle',
  },
  browser: {
    message: defineMessage({
      id: 'trialClass.systemCheck.checkFailMessage.browser',
      message: 'Download latest version of Chrome.',
    }),
    icon: 'XCircle',
  },
  screenSize: {
    message: defineMessage({
      id: 'trialClass.systemCheck.checkFailMessage.screenSize',
      message: 'Ensure that your device has a resolution of at least 1024x768.',
    }),
    icon: 'XCircle',
  },
  device: {
    message: defineMessage({
      id: 'trialClass.systemCheck.checkFailMessage.device',
      message: 'The device is not supported. Use a desktop/laptop.',
    }),
    icon: 'XCircle',
  },
  cookies: {
    message: defineMessage({
      id: 'trialClass.systemCheck.checkFailMessage.cookies',
      message: 'Enable browser cookies.',
    }),
    icon: 'XCircle',
  },
  generic: {
    message: defineMessage({
      id: 'trialClass.systemCheck.checkFailMessage.generic',
      message: 'Something went wrong. Please contact support.',
    }),
    icon: 'XCircle',
  },
}

export const MIN_SCREEN_SIZE = {
  HEIGHT: 768,
  WIDTH: 1024,
}

export const SUPPORTED_BROWSERS = {
  Chrome: 58,
  Firefox: 56,
  Safari: 11,
  Opera: 45,
}

export const UNSUPPORTED_USER_AGENT = [
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:69.0) Gecko/20100101 Firefox/69.0',
]

export const CAMERA_AND_MIC_ERRORS = {
  NOT_ALLOWED_ERROR: 'NotAllowedError',
  NOT_READABLE_ERROR: 'NotReadableError',
}

export const SYSTEM_CHECK_RESULT = {
  PASSED: 'true',
  FAILED: 'false',
}

export const IMAGE_PATH = '/images/system-check/'

export const CAM_AND_MIC_ACCESS_VIDEOS = {
  firefox:
    'https://codingal.s3.amazonaws.com/media/videos/system-check/Give_camera_and_mic_access_on_Firefox.mp4',
  safari:
    'https://codingal.s3.amazonaws.com/media/videos/system-check/Give_camera_and_mic_access_on_Safari.mp4',
  chrome:
    'https://codingal.s3.amazonaws.com/media/videos/system-check/Giving_camera_and_mic_access_on_Google_Chrome.mp4',
  microsoftedge:
    'https://codingal.s3.amazonaws.com/media/videos/system-check/Give_camera_and_mic_access_on_Edge.mp4',
}

export const VALID_BROWSER_KEYS = [
  'firefox',
  'safari',
  'chrome',
  'microsoftedge',
]
